import React from "react";
import { useAsync } from "react-async";
import { createRoot } from "react-dom/client";
import Select from "react-select";

const defaultStyleFn = (provided) => ({
    ...provided,
    fontFamily: "Droid Arabic Kufi",
});

async function getData({ sourceUrl, labelSelector, valueSelector }, { signal }) {
    const resp = await fetch(sourceUrl, { signal })
    if (!resp.ok) {
        throw new Error(resp.statusText)
    }
    return resp.json().then(d => d.map((element) => ({
        value: valueSelector(element),
        label: labelSelector(element),
    })))
}

export const ResponsiveSelect = ({
    labelSelector,
    valueSelector,
    sourceUrl,
    formElementName,
    formElId,
    externalOptions
}) => {
    const { data, error } = useAsync({ promiseFn: getData, sourceUrl, labelSelector, valueSelector })
    if (error) {
        return <pre>{error.message}</pre>
    }

    const customStyles = {
        menu: (provided) => {
            return {
                ...defaultStyleFn(provided),
                zIndex: 5,
            };
        },
        placeholder: defaultStyleFn,
        singleValue: defaultStyleFn,
    };
    const noOptions = () => (
        <div className="alert" style={{ padding: 0, margin: 0 }}>
            لا شيء!
        </div>
    );
    if (data) {
        return <Select
            styles={customStyles}
            placeholder="اختر ..."
            noOptionsMessage={noOptions}
            class="chosen-select form-control"
            isRtl={true}
            isClearable={true}
            isSearchable={true}
            name={formElementName}
            id={formElId}
            options={data}
            {...externalOptions}
        />
    }
    return null
};

export function renderSelectIn(rootSelector) {
    const globalVarName = `react_root_${rootSelector}`;
    if (!window[globalVarName]) {
        const root = document.querySelector(rootSelector);
        if (root) {
            window[globalVarName] = root;

            const reactRoot = createRoot(root);
            const valSelector = (element) => element[root.dataset.value];
            const labelSelector = (element) => element[root.dataset.label];
            const formElName = root.dataset.name;
            const formElId = root.dataset.id;
            reactRoot.render(
                <ResponsiveSelect
                    sourceUrl={root.dataset.url}
                    labelSelector={labelSelector}
                    valueSelector={valSelector}
                    formElementName={formElName}
                    formElementId={formElId}
                />
            );
            root.addEventListener("unmount", () => {
                reactRoot.unmount();
                delete window[globalVarName];
            });
        }
    }
    return [globalVarName, window[globalVarName]];
}
